import { Controller } from '@hotwired/stimulus';
import { convertToCurrency, extractYoutubeVideoIdFromUrl } from '../../shared/util';

export default class extends Controller {
  static targets = [
    'listingPreview',
    'listingPreviewPriceDisplay',
    'listingPricePositionIndicator',
    'listingPricePositionRatio',
    'websitePriceInput',
    'autoTraderWebsiteStatusSwitch',
    'autoTraderRetailerStoreStatusSwitch',
    'youtubeUrlInput',
    'youtubeEmbedPreviewWrapper',
  ];

  connect() {
    this.enablePricePositionIndicator =
      this.hasListingPricePositionIndicatorTarget && this.hasListingPricePositionRatioTarget;

    if (this.enablePricePositionIndicator) {
      const { initialRatioPercentage, autoTraderMarketAverageRetailValuation } =
        this.listingPricePositionIndicatorTarget.dataset;
      if (initialRatioPercentage) {
        this.setPricePositionIndicator(initialRatioPercentage);
      }

      if (autoTraderMarketAverageRetailValuation) {
        this.autoTraderMarketAverageRetail = autoTraderMarketAverageRetailValuation;
      }
    }

    this.setupEventListeners();
  }

  setupEventListeners() {
    this.element.addEventListener('change', (e) => {
      const listingPreviewWrapper = e.target.closest('[data-stock-form-target="listingPreview"]');
      if (listingPreviewWrapper && e.target.classList.contains('js-invoice-payment')) {
        const listingPreviewPriceDisplay = listingPreviewWrapper.querySelector(
          '[data-stock-form-target="listingPreviewPriceDisplay"]'
        );

        const listingPreviewPrice = e.target.valueAsNumber;
        listingPreviewPriceDisplay.innerText = Number.isNaN(listingPreviewPrice)
          ? 'POA'
          : convertToCurrency(listingPreviewPrice);
      }
    });

    document.addEventListener('vehicle-lookup:resultRetrieved', (e) => {
      const { valuation } = e.detail;
      const { auto_trader_market_average_retail: autoTraderMarketAverageRetail } = valuation;
      if (autoTraderMarketAverageRetail) {
        this.autoTraderMarketAverageRetail = autoTraderMarketAverageRetail;
        if (this.hasWebsitePriceInputTarget) {
          this.websitePriceInputTarget.setAttribute('max', autoTraderMarketAverageRetail * 2);
        }
      }
    });

    if (this.hasWebsitePriceInputTarget) {
      this.websitePriceInputTarget.addEventListener('input', (e) => {
        const websitePrice = e.target.valueAsNumber;

        if (Number.isNaN(websitePrice)) {
          this.setPricePositionIndicator(0);
          return;
        }

        if (this.autoTraderMarketAverageRetail) {
          const ratioPercentage = Math.ceil((websitePrice / this.autoTraderMarketAverageRetail) * 100);
          this.setPricePositionIndicator(ratioPercentage);
        }
      });
    }

    if (this.hasAutoTraderWebsiteStatusSwitchTarget) {
      this.autoTraderWebsiteStatusSwitchTarget.addEventListener('change', (e) => {
        const autoTraderWebsiteEnabled = e.target.checked;

        // trigger click on Retailer Store switch as we want to keep these in sync
        this.autoTraderRetailerStoreStatusSwitchTarget.click();

        const retailerStoreSwitchWrapper =
          this.autoTraderRetailerStoreStatusSwitchTarget.closest('.form-switch-wrapper');

        if (autoTraderWebsiteEnabled) {
          this.autoTraderRetailerStoreStatusSwitchTarget.checked = true;
          // controlling "disabled" class on wrapper rather than "disabled" attribute on input as when input is actually disabled it doesn't get attached to POST request
          // so we're just displaying overlay over the input to disable the switch without actually setting the attribute
          retailerStoreSwitchWrapper.classList.add('disabled');
        } else {
          this.autoTraderRetailerStoreStatusSwitchTarget.checked = false;
          // controlling "disabled" class on wrapper rather than "disabled" attribute on input as when input is actually disabled it doesn't get attached to POST request
          // so we're just displaying overlay over the input to disable the switch without actually setting the attribute
          retailerStoreSwitchWrapper.classList.remove('disabled');
        }
      });
    }

    if (this.hasYoutubeUrlInputTarget) {
      this.prepareYoutubeIframeApiScript();

      if (this.youtubeUrlInputTarget.value) {
        // handle page load
        const url = this.youtubeUrlInputTarget.value;
        const urlValid = this.checkYoutubeUrlValidity(url);
        if (urlValid) {
          this.displayYoutubeVideo(url);
        } else {
          this.handleYoutubeUrlError();
        }
      }

      this.youtubeUrlInputTarget.addEventListener('change', (e) => {
        // handle input changes
        if (this.player) {
          this.player.destroy();
        }

        const url = e.target.value;
        this.youtubeUrlInputTarget.classList.remove('is-invalid');

        if (!url) {
          this.youtubeEmbedPreviewWrapperTarget.classList.add('d-none');
          return;
        }

        const urlValid = this.checkYoutubeUrlValidity(url);
        if (!urlValid) {
          this.handleYoutubeUrlError();
          return;
        }

        this.displayYoutubeVideo(url);
      });
    }
  }

  setPricePositionIndicator(ratioPercentage) {
    if (!this.enablePricePositionIndicator) {
      return;
    }

    const progressBarBackgroundWidth = ratioPercentage / 2;
    const indicatorProgressBar = this.listingPricePositionIndicatorTarget.querySelector('.progress-bar');

    switch (true) {
      case ratioPercentage < 40:
        indicatorProgressBar.style.backgroundColor = 'rgb(255, 13, 13)';
        break;
      case ratioPercentage >= 40 && ratioPercentage <= 90:
        indicatorProgressBar.style.backgroundColor = 'rgb(255, 142, 21)';
        break;
      case ratioPercentage >= 90 && ratioPercentage <= 100:
        indicatorProgressBar.style.backgroundColor = 'rgb(250, 183, 51)';
        break;
      case ratioPercentage > 100 && ratioPercentage < 120:
        indicatorProgressBar.style.backgroundColor = 'rgb(82, 179, 52)';
        break;
      case ratioPercentage >= 120:
        indicatorProgressBar.style.backgroundColor = 'rgb(3, 143, 3)';
        break;
      default:
        break;
    }

    indicatorProgressBar.style.width = `${progressBarBackgroundWidth}%`;
    this.listingPricePositionIndicatorTarget.setAttribute('aria-valuenow', ratioPercentage);
    this.listingPricePositionRatioTarget.innerText = `${
      ratioPercentage <= 200 ? `${ratioPercentage}%` : '200%+'
    }`;
  }

  handleYoutubeUrlError() {
    const errorDisplay = this.youtubeUrlInputTarget.parentElement.querySelector('.invalid-feedback');
    errorDisplay.innerText = 'YouTube URL is invalid';
    this.youtubeUrlInputTarget.classList.add('is-invalid');
    this.youtubeEmbedPreviewWrapperTarget.classList.add('d-none');
  }

  prepareYoutubeIframeApiScript() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  initYoutubePlayer(videoId) {
    const stopVideo = (player) => {
      // player.stopVideo();
    };

    const onPlayerReady = (event) => {
      // event.target.playVideo();
    };

    const done = false;
    const onPlayerStateChange = (event) => {
      // if (event.data === YT.PlayerState.PLAYING && !done) {
      //   setTimeout(() => stopVideo(event.target), 6000);
      //   done = true;
      // }
    };

    const player = new YT.Player('yt-player', {
      height: '390',
      width: '640',
      videoId,
      playerVars: {
        playsinline: 1,
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });

    return player;
  }

  checkYoutubeUrlValidity(url) {
    const youtubeUrlRegExp = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    return youtubeUrlRegExp.test(url);
  }

  displayYoutubeVideo(url) {
    this.youtubeEmbedPreviewWrapperTarget.classList.remove('d-none');
    setTimeout(() => {
      const videoId = extractYoutubeVideoIdFromUrl(url);
      this.player = this.initYoutubePlayer(videoId);
    }, 1000);
  }
}
