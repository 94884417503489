import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['stockPicker'];

  connect() {
    this.financeCalculatorLoader = document.querySelector('[data-finance-calculator-target="loader"]');

    this.setupEventListeners();
  }

  setupEventListeners() {
    if (this.hasStockPickerTarget) {
      this.stockPickerTarget.addEventListener('change', (e) => {
        if (e.target.value) {
          if (this.financeCalculatorLoader) {
            this.financeCalculatorLoader.classList.remove('d-none');
          }
          window.location = `${e.target.value}#financeCalculator`;
        }
      });
    }
  }
}
