import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['collapse', 'trigger'];

  connect() {
    this.collapseTarget.addEventListener('show.bs.collapse', (e) => {
      this.triggerOriginalText = this.triggerTarget.querySelector('[data-text]').innerText;
      this.triggerTarget.querySelector('[data-text]').innerText = 'View less';
    });

    this.collapseTarget.addEventListener('hide.bs.collapse', (e) => {
      this.triggerTarget.querySelector('[data-text]').innerText = 'View more';
    });
  }
}
