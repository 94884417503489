export class Collapse {
  constructor(collapseWrapper) {
    this.maxPreviewHeight = parseInt(collapseWrapper.dataset.maxPreviewHeight, 10) || 180;
    collapseWrapper.style.setProperty('--max-preview-height', `${this.maxPreviewHeight}px`);

    this.collapseContent = collapseWrapper.querySelector('.js-collapse-content');
    this.expandSection = collapseWrapper.querySelector('.js-collapse-expand-section');
    this.expandTrigger = collapseWrapper.querySelector('.js-collapse-expand-trigger');
    this.expandTriggerText = this.expandTrigger.querySelector('.js-collapse-expand-trigger-text');
    this.setupEventListeners();
    this.hideExpandSectionIfRedundant();
  }

  setupEventListeners() {
    this.collapseContent.addEventListener('show.bs.collapse', (e) => {
      if (this.expandTriggerText.dataset.openStateText !== '') {
        this.expandTriggerText.innerText = this.expandTriggerText.dataset.openStateText;
      }
      e.target.classList.add('fade-hidden');
    });

    this.collapseContent.addEventListener('hide.bs.collapse', (e) => {
      if (this.expandTriggerText.dataset.closedStateText !== '') {
        this.expandTriggerText.innerText = this.expandTriggerText.dataset.closedStateText;
      }
      e.target.classList.remove('fade-hidden');
    });
  }

  hideExpandSectionIfRedundant() {
    const contentHeight = this.getTotalHeightOfCollapseContent();

    if (contentHeight <= this.maxPreviewHeight) {
      this.collapseContent.classList.remove('has-more-content');
      this.expandSection.classList.add('d-none');
    }
  }

  getTotalHeightOfCollapseContent() {
    const collapseChildren = this.collapseContent.children;
    let contentHeight = 0;

    [...collapseChildren].forEach((collapseChild) => {
      const computedStyles = window.getComputedStyle(collapseChild);
      const marginTop = parseInt(computedStyles.getPropertyValue('margin-top').split('px')[0], 10);
      const marginBottom = parseInt(computedStyles.getPropertyValue('margin-bottom').split('px')[0], 10);
      contentHeight += collapseChild.getBoundingClientRect().height + marginTop + marginBottom;
    });

    return contentHeight;
  }
}
