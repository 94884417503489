export class StickyNavTabs {
  constructor(overviewCategoriesPreview) {
    this.overviewCategoriesPreview = overviewCategoriesPreview;
    this.setupEventListeners();
  }

  setupEventListeners() {
    const tabEls = this.overviewCategoriesPreview.querySelectorAll('[data-bs-toggle="tab"]');
    [...tabEls].forEach((tabEl) => {
      tabEl.addEventListener('show.bs.tab', (e) => {
        const activatedTab = e.target;

        activatedTab.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      });
    });

    const navTabsList = document.querySelector('.nav-tabs-sticky');
    const prevBtn = document.querySelector('[data-sticky-nav-tab-prev]');
    const nextBtn = document.querySelector('[data-sticky-nav-tab-next]');
    prevBtn.addEventListener('click', (e) => {
      const newScrollPosition = navTabsList.scrollLeft - 200;
      navTabsList.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
    });
    nextBtn.addEventListener('click', (e) => {
      const newScrollPosition = navTabsList.scrollLeft + 200;
      navTabsList.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
    });

    const navTabsListActiveItem = document.querySelector('.nav-tabs-sticky .nav-link.active');
    navTabsListActiveItem.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest',
    });

    const navTabsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target.dataset.stickyNavTabsRef === 'start') {
          this.overviewCategoriesPreview.classList.toggle('at-start', entry.isIntersecting);
        }
        if (entry.target.dataset.stickyNavTabsRef === 'end') {
          this.overviewCategoriesPreview.classList.toggle('at-end', entry.isIntersecting);
        }
      });
    });

    document
      .querySelectorAll('[data-sticky-nav-tabs-ref]')
      .forEach((startEnd) => navTabsObserver.observe(startEnd));
    navTabsObserver.observe(navTabsList);
  }
}
