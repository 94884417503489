import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'display'];

  connect() {
    this.inputTarget.addEventListener('input', (e) => {
      const { value, min, max } = e.target;
      const percentage = (100 * (value - min)) / (max - min);
      this.inputTarget.style.setProperty('--slider-progress', `${percentage}%`);
      this.displayTarget.innerText = value;
    });
  }
}
