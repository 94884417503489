import $ from 'jquery';

export class AddressLookup {
  constructor(lookup) {
    this.lookupInputWrapper = lookup.querySelector('.js-address-lookup-input-wrapper');
    this.lookupInput = lookup.querySelector('.js-address-lookup-input');
    this.lookupInputDefaultPlaceholder = this.lookupInput.placeholder;
    this.lookupOutputForm = lookup.querySelector('.js-address-lookup-output-form');
    this.manualEntryBtn = lookup.querySelector('.js-address-lookup-manual-entry-btn');
    this.changeAddressBtn = lookup.querySelector('.js-address-lookup-change-address-btn');
    this.countyMappings = {
      'Central Bedfordshire': 'Bedfordshire',
    };

    const autocompleteConfig = {
      fields: ['address_components', 'name', 'types'],
      componentRestrictions: {
        country: ['GB'],
      },
    };

    if (window.google) {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        this.lookupInput,
        autocompleteConfig
      );
    }

    this.setupEventListeners();
  }

  setupEventListeners() {
    // Hack preventing autocomplete on lookup field covering actual places dropdown
    const autocompleteAttrObserverHack = new MutationObserver(() => {
      autocompleteAttrObserverHack.disconnect();
      this.lookupInput.setAttribute('autocomplete', 'new-password');
    });

    autocompleteAttrObserverHack.observe(this.lookupInput, {
      attributes: true,
      attributeFilter: ['autocomplete'],
    });
    // Hack end

    if (window.google) {
      window.google.maps.event.addListener(this.autocomplete, 'place_changed', () =>
        this.handlePlaceSelection()
      );
    }

    if (this.manualEntryBtn) {
      this.manualEntryBtn.addEventListener('click', (e) => {
        // this.lookupInput.removeAttribute('required');
        this.lookupInput.value = '';
        this.showAddressForm();
      });
    }

    if (this.changeAddressBtn) {
      this.changeAddressBtn.addEventListener('click', (e) => {
        this.lookupInput.setAttribute('required', true);
        $(this.lookupInputWrapper).slideDown();
        $(this.changeAddressBtn).slideUp();
        $(this.lookupOutputForm).slideUp(() => {
          this.clearOutputForm();
        });
      });
    }

    document.addEventListener('customer-lookup:resultRetrieved', (e) => {
      // expand address form when existing customer lookup returns a result
      // essentially acts as if address lookup was performed, but it just uses existing customer address instead
      if (this.manualEntryBtn) {
        this.manualEntryBtn.click();
      }
    });
  }

  clearOutputForm() {
    const fields = this.lookupOutputForm.querySelectorAll('.form-control');
    fields.forEach((field) => {
      field.value = '';
    });
  }

  handlePlaceSelection() {
    const placeDetails = this.autocomplete.getPlace();
    this.lookupInput.placeholder = this.lookupInputDefaultPlaceholder;
    this.lookupInput.value = '';
    this.populateAddressIntoFields(placeDetails);
    this.showAddressForm();
  }

  extractAddressComponents(placeDetails) {
    const { name, address_components: addressComponents, types: placeTypes } = placeDetails;

    const result = {
      line_1: '',
      line_2: '',
      post_town: '',
      county: '',
      country: '',
      postcode: '',
    };

    let streetNumber = '';
    let route = '';
    let premise = '';

    addressComponents.forEach((component) => {
      // eslint-disable-next-line
      switch (component.types[0]) {
        case 'street_number':
          streetNumber = component.long_name;
          break;
        case 'route':
          route = component.long_name;
          break;
        case 'premise':
          premise = component.long_name;
          break;
        case 'locality':
          result.line_2 = component.long_name;
          break;
        case 'postal_town':
          result.post_town = component.long_name;
          break;
        case 'administrative_area_level_2':
          result.county = component.long_name;
          break;
        case 'country':
          result.country = component.long_name;
          break;
        case 'postal_code':
          result.postcode = component.long_name;
          break;
      }
    });

    if (placeTypes.includes('establishment') && name && premise && route && result.line_2 === '') {
      // e.g. easyHotel Milton Keynes
      result.line_1 = name;
      if (streetNumber) {
        result.line_2 = `${premise}, ${streetNumber} ${route}`;
      } else {
        result.line_2 = `${premise}, ${route}`;
      }
    } else if (placeTypes.includes('establishment') && name && route && result.line_2 === '') {
      result.line_1 = name;
      if (streetNumber) {
        // e.g. Spaces Milton Keynes (100 Avebury Boulevard)
        result.line_2 = `${streetNumber} ${route}`;
      } else {
        result.line_2 = route;
      }
    } else if (placeTypes.includes('establishment') && name && premise && route) {
      // e.g. FuseMetrix
      result.line_1 = `${name}, ${premise}, ${route}`;
    } else if (placeTypes.includes('establishment') && name && route) {
      // e.g. BINCA Milton Keynes
      result.line_1 = `${name}, ${route}`;
    } else if (placeTypes.includes('premise') && premise && route) {
      // e.g. Norfolk House
      result.line_1 = premise;
      result.line_2 = route;
    } else {
      // Construct line1
      if (premise) {
        result.line_1 += `${premise}, `;
      }
      if (streetNumber && route) {
        result.line_1 += `${streetNumber} ${route}`;
      } else if (route) {
        result.line_1 += route;
      }
    }

    // Clean up line_1 & line_2
    result.line_1 = result.line_1.replace(/^,\s*/, '').replace(/,\s*$/, '');
    result.line_2 = result.line_2.replace(/^,\s*/, '').replace(/,\s*$/, '');

    return result;
  }

  populateAddressIntoFields(placeDetails) {
    const addressInfo = this.extractAddressComponents(placeDetails);
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const addressField in addressInfo) {
      if (addressField !== 'country') {
        if ($(`.js-address-lookup-populate-${addressField}`).length) {
          $(`.js-address-lookup-populate-${addressField}`).val(addressInfo[addressField]);
        }
      }
    }

    this.focusFirstEmptyField();
  }

  focusFirstEmptyField() {
    const addressFields = this.lookupOutputForm.querySelectorAll('.form-control');
    const firstEmptyField = [...addressFields].find((field) => field.value === '');
    if (firstEmptyField) {
      setTimeout(() => {
        firstEmptyField.focus();
      }, 0);
    }
  }

  showAddressForm() {
    this.lookupInput.removeAttribute('required');
    $(this.lookupInputWrapper).slideUp();
    $(this.changeAddressBtn).slideDown();
    $(this.lookupOutputForm).slideDown();
  }
}

// have to make it globally accessible on window object, as google places API needs this as a callback
window.initAutocomplete = () => {
  const lookup = document.querySelector('.js-address-lookup');
  if (lookup) {
    const addressLookup = new AddressLookup(lookup);
  }
};
