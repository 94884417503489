export class ColorPicker {
  constructor(colorPicker) {
    this.colorPickerWrapper = colorPicker.querySelector('.js-color-picker-wrapper');
    this.colorPickerField = colorPicker.querySelector('.js-color-picker-field');

    this.setupEventListeners();
  }

  setupEventListeners() {
    ['input', 'propertychange'].forEach((event) => {
      this.colorPickerField.addEventListener(event, (e) => {
        this.colorPickerWrapper.style.backgroundColor = e.target.value;
      });
    });
  }
}
