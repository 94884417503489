import { Controller } from '@hotwired/stimulus';
import { apiClient } from '../../shared/apiClient';

export default class extends Controller {
  static targets = ['loader'];

  connect() {
    this.provider = this.element.dataset.provider;

    const loaderDuration = this.provider === 'close_brothers' ? 2800 : 1700; // show loader for longer as close brother calculator seems to be taking longer to load
    const loaderVisibleOnLoad = this.hasLoaderTarget && !this.loaderTarget.classList.contains('d-none');
    setTimeout(() => {
      if (this.hasLoaderTarget && loaderVisibleOnLoad) {
        this.loaderTarget.classList.add('d-none');
      }
    }, loaderDuration);

    /* eslint no-restricted-syntax: "off" */
    const financeCalculatorMutationObserverCallback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        // if (mutation.target.classList.contains('hp-payments')) {
        //   console.log('hp mutation');
        //   console.log(mutation);
        //   console.log(mutation.target.innerText);
        // }
        // if (mutation.target.classList.contains('pcp-payments')) {
        //   console.log('pcp mutation');
        //   console.log(mutation);
        //   console.log(mutation.target.innerText);
        // }

        // currently only working for Evolution Funding since Close Brothers display the calculator in an iframe and we cannot scrape it due to CORS error
        if (
          this.provider === 'evolution_funding' &&
          mutation.addedNodes.length &&
          mutation.addedNodes[0]?.classList?.contains('financeproposal-calc-wrapper')
        ) {
          setTimeout(async () => {
            // now we can safely retrieve values as the calculator has been appended to DOM
            // & prices have been calculated
            let hpInstalment = null;
            let pcpInstalment = null;

            const hpInstalmentEl = mutation.addedNodes[0].querySelector('.hp-payments');
            if (hpInstalmentEl) {
              const hpInstalmentAsText = hpInstalmentEl.innerText;
              // need to get rid of £ sign + thousands part is separated with a comma and need to get rid of it for parseFloat() to work
              hpInstalment = parseFloat(hpInstalmentAsText.split('£')[1].replace(/,/g, ''));
            }

            const pcpInstalmentEl = mutation.addedNodes[0].querySelector('.pcp-payments');
            if (pcpInstalmentEl) {
              const pcpInstalmentAsText = pcpInstalmentEl?.innerText;
              // need to get rid of £ sign + thousands part is separated with a comma and need to get rid of it for parseFloat() to work
              pcpInstalment = parseFloat(pcpInstalmentAsText.split('£')[1].replace(/,/g, ''));
            }

            try {
              const res = await apiClient(this.element.dataset.updateListingFinanceValuesUrl, {
                body: {
                  hpValue: hpInstalment,
                  pcpValue: pcpInstalment,
                },
              });
            } catch (error) {
              console.error('Unable to update finance prices');
            }

            observer.disconnect();
          }, 2000);
        }
      }
    };

    const observer = new MutationObserver(financeCalculatorMutationObserverCallback);
    observer.observe(this.element, { attributes: true, childList: true, subtree: true });

    this.setupEventListeners();
  }

  setupEventListeners() {}
}
