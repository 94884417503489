import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    listId: String,
    url: String,
  };

  connect() {
    this.page = 1;
    this.fetchingData = false;
    useIntersection(this);

    // Add a class to an element we are about to add to the page
    document.addEventListener('turbo:before-stream-render', function (event) {
      if (event.target.firstElementChild instanceof HTMLTemplateElement) {
        const newElementsFromStreamArr = [...event.target.templateElement.content.children];
        newElementsFromStreamArr.forEach((el) => {
          el.classList.add('fadeInUp');
        });
      }
    });
  }

  async appear() {
    if (this.fetchingData || this.hasNoMoreResultsItem) return;
    this.fetchingData = true;
    this.page += 1;
    // console.log('load-more', this.page, this.listIdValue, this.urlValue);

    await get(this.urlValue, {
      query: {
        page: this.page,
        turbo_target: this.listIdValue,
      },
      responseKind: 'turbo-stream',
    });

    this.fetchingData = false;
  }

  urlValueChanged() {
    this.page = 1;
  }

  get hasNoMoreResultsItem() {
    return document.getElementById(this.listIdValue).querySelector(`[data-no-more-results]`) != null;
  }
}
