import { Controller } from '@hotwired/stimulus';
import Carousel from 'bootstrap/js/dist/carousel';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export default class extends Controller {
  static targets = [
    'carousel',
    'thumbnailsCarouselTop',
    'thumbnailsCarouselMiddle',
    'thumbnailsCarouselBottom',
  ];

  static values = {
    currentIndex: { type: Number, default: 0 },
  };

  initialize() {
    this.currentIndex = 0;
  }

  connect() {
    if (this.hasThumbnailsCarouselTopTarget) {
      this.topThumbnailsCarousel = new Carousel(this.thumbnailsCarouselTopTarget);
    }
    if (this.hasThumbnailsCarouselMiddleTarget) {
      this.middleThumbnailsCarousel = new Carousel(this.thumbnailsCarouselMiddleTarget);
    }
    if (this.hasThumbnailsCarouselBottomTarget) {
      this.bottomThumbnailsCarousel = new Carousel(this.thumbnailsCarouselBottomTarget);
    }
    this.mainCarousel = new Carousel(this.carouselTarget);
    this.slideCount = this.carouselTarget.querySelectorAll('.carousel-item').length;
    this.carouselTarget.addEventListener('slide.bs.carousel', ({ from, to, direction }) => {
      if (direction === 'left') {
        this.topThumbnailsCarousel?.next();
        this.middleThumbnailsCarousel?.next();
        this.bottomThumbnailsCarousel?.next();
      } else {
        this.topThumbnailsCarousel?.prev();
        this.middleThumbnailsCarousel?.prev();
        this.bottomThumbnailsCarousel?.prev();
      }
      // this.currentIndex = to;

      const carouselSlides = this.carouselTarget.querySelectorAll('.carousel-item');

      // this.element.querySelectorAll('.carousel-item img').forEach((image) => {
      //   image.addEventListener('load', (e) => {
      //     e.target.parentNode.classList.remove('is-loading');
      //   });
      // });

      // const slideToPreload =
      //   carouselSlides[(this.currentIndex + 3) % carouselSlides.length];
      // return this.itemArr[(this.currentIndex - 3 + this.itemArr.length) % this.itemArr.length];
      const slideToPreload =
        carouselSlides[(this.currentIndex - 3 + carouselSlides.length) % carouselSlides.length];

      slideToPreload?.querySelector('img').setAttribute('loading', '');
    });

    const lightboxInstance = this.initLightbox();
    if (lightboxInstance) {
      lightboxInstance.on('tapAction', (e) => {
        const target = e?.originalEvent?.target;
        if (target) {
          const shouldClose =
            target.matches('.pswp__item') &&
            !target.matches('.pswp__img') &&
            !target.matches('.pswp__top-bar');

          if (shouldClose) {
            lightboxInstance.pswp.close();
          }
        }
      });

      const thumbnails = document.querySelectorAll('[data-thumbnails-wrapper] .carousel-item');
      [...thumbnails].forEach((thumbnail) => {
        thumbnail.addEventListener('click', (e) => {
          const fullImageIndex = parseInt(e.target.dataset.index, 10);
          lightboxInstance.loadAndOpen(fullImageIndex);
        });
      });
    }
  }

  initLightbox() {
    const el = this.element?.querySelector('[data-slides-wrapper]');

    if (!el) {
      return null;
    }

    const lightbox = new PhotoSwipeLightbox({
      gallery: el,
      children: 'a',
      pswpModule: PhotoSwipe,
      showHideAnimationType: 'fade',
      preload: [1, 3],
      wheelToZoom: true,
      preloaderDelay: 200,
      maxZoomLevel: 1.5,
    });

    // parse data-youtube-video-url attribute
    lightbox.addFilter('itemData', (itemData, index) => {
      const { youtubeVideoUrl } = itemData.element.dataset;
      if (youtubeVideoUrl) {
        itemData.youtubeVideoUrl = youtubeVideoUrl;
      }
      return itemData;
    });

    lightbox.init();

    // override slide content
    lightbox.on('contentLoad', (e) => {
      const { content } = e;
      if (content.type === 'youtube-video') {
        // prevent the deafult behavior
        e.preventDefault();

        // Create a container for iframe
        // and assign it to the `content.element` property
        content.element = document.createElement('div');
        content.element.className = 'pswp__youtube-video-container';

        const iframe = document.createElement('iframe');
        iframe.setAttribute('allowfullscreen', '');
        iframe.src = content.data.youtubeVideoUrl;
        content.element.appendChild(iframe);
      }
    });

    return lightbox;
  }
}
